//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange } from "@/commons";
import { mapState } from "vuex";
import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
export default {
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    tooltip: () => import("@/components/textTooltip"),
    ProcessDataDialog: () => import("@/components/Dialogs/processDataDialog"),
  },
  mixins: [MixinTeamOptions, MixinWebrtc],
  data() {
    return {
      functionName: "敏感词记录",
      NeedDataChannel: true,
      IsRealTimeScreenshot: true, //是否显示截图
      uid: null,
      word: null,
      ds: null, //开始时间
      de: null, //结束时间
      dialogs: {
        software: {
          open: false,
          options: {},
          title: "",
        },
      },
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalCount: 0,
      }, //分页
      loading: false,
      memberData: [],
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
      dateType: 1, //时间查询类型
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  beforeDestroy() {
    this.HangUpAll();
  },
  methods: {
    sendWebrtcRequestNoIdentitySync(request, successCallback, failCallback) {
      this.sendWebrtcRequestNoIdentity(request)
        .then((resp) => successCallback?.(resp))
        .catch((error) => {
          failCallback?.(error);
        });
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        await this.Call(identity);
        var b = this.SendRequest(null, identity, request);
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    imgChange,
    /**
     * 查看某个敏感词
     */
    handleKeyWord(val, item) {
      this.dialogs.software = {
        open: true,
        title: `进程名"${val}"`,
        options: {
          startTime: this.ds,
          endTime: this.de,
          users: [item.UserId],
          teamId: this.teamValue,
          datetype: this.dateType,
          processName: [],
          softwareName: [],
          formName: [val],
        },
      };
    },
    /**
     * 获取列表数据
     */
    async handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.HangUpAll();
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          console.log(error);
        }
      }
      this.pageData.pageIndex = 1;
      this.memberData = [];
      this.getWordData();
    },
    /**
     * 获取敏感词数据
     */
    async getWordData() {
      this.loading = true;
      this.memberData = [];
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      let params = {
        startTime: this.ds,
        endTime: this.de,
        teamId: this.teamValue,
        users: this.selMem.map((m) => m.UsId),
      };
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          let data = await this.getWordDataLocal(identity, params);
          this.memberData.push({
            UserId: this.selMem[0].UsId,
            UserName: this.selMem[0].Name,
            Picture: this.selMem[0].Picture,
            Words: data.map((m) => m.Word),
          });
        } catch (error) {
          this.$notify({
            message: `获取敏感词数据失败，${error}`,
            type: "info",
          });
        }
        this.loading = false;
        return;
      }
      let data = {
        datestart: this.ds,
        dateend: this.de,
        UsIds: this.selMem.map((m) => m.UsId),
        page: this.pageData.pageIndex,
        pageCount: this.pageData.pageSize,
        teamId: this.teamValue,
        dt: this.dateType,
      };
      this.$http
        .post("/SensitiveWord/GetMemberSensitiveWord.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.memberData = resp.data.Data;
            this.pageData.totalCount = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    getWordDataLocal(clientIdentity, data) {
      var _this = this;
      var promise = new Promise((resolve, reject) => {
        if (!_this.isLocalStorage) {
          reject("当前团队未启用本地存储");
        }
        var request = new Request();
        request.Url = "/Process/SensitiveWords";
        request.ContentType = "Json";
        const token = _this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          reject(error);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            resolve(result.Data);
          } else {
            reject(result.Message);
          }
        };
        this.Call(clientIdentity)
          .then(() => {
            var b = this.SendRequest(null, clientIdentity, request);
            if (!b) {
              reject("发送请求失败");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
      return promise;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    pickDate() {
      let _this = this;
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data.filter(
              (m) => m.Functions.indexOf("敏感词记录") != -1
            );
            var defaultTeamValue = this.user.DefaultTeamId;
            if (this.teamOptions.length > 0 && defaultTeamValue) {
              var team = this.teamOptions.find((m) => m.Id == defaultTeamValue);
              if (team) {
                this.teamValue = team.Id;
              }
            }
            if (this.teamValue) {
              this.handleGetData();
            }
          }
        });
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange(val) {
      const team = this.teamOptions.find((m) => m.Id == val);
      if (team) {
        this.IsRealTimeScreenshot = team.IsRealTimeScreenshot; //是否显示实时截图
      }
      this.selMem = [];
    },
  },
};
